import FinanceApi from '@/services/FinanceApi';

const get = async (id) => {
    return FinanceApi.get('/refunds/' + id);
}

const store = async (data) => {
    if (data.id > 0) {
        return FinanceApi.put('/refunds/' + data.id, data);
    }
    return FinanceApi.post('/refunds', data);
}

const del = async (id) => {
    return FinanceApi.delete('/refunds/' + id);
}

const getRefundForm = async (id) => {
    return FinanceApi.get('/refunds/form/' + id);
}

const report = async (config) => {
    return FinanceApi.get('/refunds/report', config);
}

export default {
    get,
    store,
    del,
    getRefundForm,
    report
}
